<template>
  <div class="h-full flex flex-col px-0 pt-2 pr-2 pb-4">
    <div class="bg-green-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex items-center">
      <div class="flex my-auto mx-1 items-center">
        <div class="flex font-medium text-gray-900 my-2 rounded-sm text-xs">
          FILTER: LOCATION:
          <select
            v-model="FILTER_LOCATION"
            class="mx-2 rounded-sm"
          >
            <option value>no filter</option>
            <option
              v-for="device in DEVICES_WITH_DESTINATION"
              :key="device.id"
              :value="device.playerid"
            >{{ device.playerid }} : {{ device.location_from }} -> {{
                device.location_to
              }}</option>
          </select>
          CHANNEL:
          <select
            v-model="FILTER_PREFIX"
            class="mx-2 rounded-sm"
          >
            <option value>no filter</option>
            <option>nachrichten</option>
            <option>werbung</option>
            <option>custom</option>
            <option>idle</option>
          </select>
          HIGHLIGHT:
          <input
            v-model="MARK_MEDIA"
            type="text"
            class="mx-2 rounded-sm w-28 text-center"
          />
          (CasE-sENsiTiVE)
        </div>
      </div>
      <div class="flex items-center">
        <ScaleLoader
          :height="20"
          :radius="2"
          v-show="playoutSocket != null"
          class="flex"
          :color="`#FFFFFF`"
        />
      </div>
      <div v-show="FILTER_LOCATION!='' && FILTER_PREFIX===''  && !show_live_stream && this.playoutSocket != null">
        <button
          class="flex font-normal px-2 mr-1 border border-white my-2 rounded-sm"
          v-on:click.prevent="show_live_stream=true"
        >
          START:LIVE-VIEW
        </button>
      </div>
      <div>
        <button
          class="flex font-normal px-2 mr-1 border border-white my-2 rounded-sm"
          v-on:click.prevent="CHANGE_PLAYOUT_SOCKET_STATE()"
        >
          {{ playoutSocket != null ? "DISCONNECT:TAP" : "CONNECT:TAP" }}
        </button>
      </div>
    </div>
    <div class="flex-col px-1 py-1 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none max-h-full">
      <div
        v-for="line in FILTERED_LOG_STREAM"
        :key="line.id"
        class="font-mono text-xs px-1 leading-5"
      >
        <div
          class="border-b border-dashed font-mono flex flex-row"
          :class="
            MARK_MEDIA != '' && line.filename.includes(MARK_MEDIA)
              ? 'bg-gray-700 text-gray-50'
              : 'text-gray-900 bg-gray-100'
          "
        >
          <div class="w-52">
            <span class="font-semibold">"{{
                new Date(line.created_at)
                  .toLocaleTimeString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  })
                  .split(", ")
                  .join(" ")
              }}"</span>
          </div>
          <div class="w-52">
            ID:
            <span class="font-semibold">{{ line.player_name }}</span>
          </div>
          <div class="w-52">
            AD.ID:
            <span class="font-semibold">{{ line.location_id }}</span>
          </div>
          <div class="w-64">
            LOC:FROM:
            <span class="font-semibold">{{ SET_LOCATION_FROM(line) }}</span>
          </div>
          <div class="w-64">
            LOC:TO:
            <span class="font-semibold">{{ SET_LOCATION_TO(line) }}</span>
          </div>
          <div
            class="flex flex-row hover:underline cursor-pointer"
            v-on:click.prevent="
              show_visual_feedback = true;
              preview_filename = line.prefix + '/' + line.filename;
            "
          >
            MEDIA:
            <div class="font-semibold ml-2 w-24">{{ line.prefix }}</div>
            /
            <div class="font-semibold ml-4">{{ line.filename }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="show_visual_feedback || show_live_stream"
      class="fixed z-10 inset-0 overflow-y-auto"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          class="inline-block bg-gray-700 rounded-sm px-2 py-2 shadow-md transform transition-all align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="text-center">
            <h3
              class="text-lg leading-6 font-medium text-gray-50"
              id="modal-headline"
            >
              {{show_live_stream ? 'LIVE-VIEW - ':''}} {{ preview_filename || "waiting..." }}
            </h3>
          </div>
          <div class="max-w-4xl max-h-full flex flex-col justify-between bg-gray-100 mt-2">
            <div
              v-if="preview_filename.includes('.jpg')"
              class="bg-blue-300 w-full h-full"
            >
              <img
                :src="
                  'https://s3.infra.xcm.de/content/station/' +
                    preview_filename
                "
                class="w-full h-full"
                alt
              />
            </div>

            <div
              v-else
              class="bg-green-300 w-full h-full"
            >
              <video
                autoplay
                muted
                controls
                class="w-full h-full"
              >
                <source
                  class="w-full h-full"
                  :src="
                    'https://s3.infra.xcm.de/content/station/' +
                      preview_filename
                  "
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div
            class="mx-auto mt-2 flex items-center justify-center h-10 w-10 rounded-full bg-red-500 cursor-pointer"
            v-on:click.prevent="
              show_visual_feedback = false;
              show_live_stream = false;
              preview_filename = '';
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="film w-6 h-6 text-gray-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ScaleLoader } from "@saeris/vue-spinners";
export default {
  name: "Stationtap",
  components: { ScaleLoader },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {
    this.GET_DEVICE_CONFIGS();
  },
  beforeDestroy() {
    if (this.playoutSocket != null) {
      this.playoutSocket.close();
      this.playoutSocket = null;
    }
  },
  data() {
    return {
      USER_OBJECT: {},
      AUTH_HEADER: "",
      LOG_STREAM: [],
      playoutSocket: null,
      DEVICES: [],
      FILTER_LOCATION: "",
      FILTER_PREFIX: "",
      MARK_MEDIA: "",
      show_visual_feedback: false,
      preview_filename: "",
      show_live_stream: false,
    };
  },
  computed: {
    FILTERED_LOG_STREAM: function () {
      let FILTERED_STREAM = this.LOG_STREAM;
      if (this.FILTER_LOCATION != "") {
        FILTERED_STREAM = FILTERED_STREAM.filter((item) => {
          return item.player_name == this.FILTER_LOCATION;
        });
      }
      if (this.FILTER_PREFIX != "") {
        FILTERED_STREAM = FILTERED_STREAM.filter((item) => {
          return item.prefix == this.FILTER_PREFIX;
        });
      }
      return FILTERED_STREAM;
    },
    DEVICES_WITH_DESTINATION: function () {
      return this.DEVICES.filter((item) => {
        return item.location_to != "NOT_SET";
      });
    },
  },
  methods: {
    CHANGE_PLAYOUT_SOCKET_STATE: function () {
      this.playoutSocket != null
        ? this.DISCONNECT_PLAYOUT_STREAM()
        : this.CONNECT_PLAYOUT_STREAM();
    },
    CONNECT_PLAYOUT_STREAM: function () {
      let that = this;
      let ws = new WebSocket("wss://c2.infra.xcm.de/ws/logstream/stationtap");
      ws.onopen = function () {
        let authHeader = JSON.parse(localStorage.getItem("AUTH_HEADER"));
        ws.send(authHeader);
        that.playoutSocket = ws;
      };
      ws.onmessage = function (event) {
        if (JSON.parse(event.data) == "AUTHFAILED") {
          that.DISCONNECT_PLAYOUT_STREAM();
          localStorage.removeItem("USER_OBJECT");
          localStorage.removeItem("AUTH_HEADER");
          that.$store.commit("UPDATE_USER_OBJECT", null);
          that.$store.commit("UPDATE_AUTH_HEADER", null);
          that.$router.push("/");
          return false;
        } else if (JSON.parse(event.data) == "AUTHOK") {
          return false;
        } else {
          let item = JSON.parse(event.data);
          if (
            that.show_live_stream &&
            that.FILTER_LOCATION != "" &&
            that.FILTER_LOCATION === item.player_name
          ) {
            that.preview_filename = item.prefix + "/" + item.filename;
          }
          that.ADD_TO_LOGSTREAM(JSON.parse(event.data));
        }
      };
      ws.onerror = function (event) {
        // console.log("ERROR" + event);
        if (event) return false;
      };
    },
    DISCONNECT_PLAYOUT_STREAM: function () {
      let ws = this.playoutSocket;
      ws.close();
      this.playoutSocket = null;
    },
    ADD_TO_LOGSTREAM: function (logline) {
      let arr = this.LOG_STREAM;
      while (arr.length > 499) {
        arr.pop();
      }
      arr.unshift(logline);
      this.LOG_STREAM = arr;
    },
    GET_DEVICE_CONFIGS: function () {
      this.$store.dispatch("GET_DEVICE_CONFIGS", {}).then(
        (response) => {
          this.DEVICES = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    SET_LOCATION_FROM: function (line) {
      let device = this.DEVICES.filter((dev) => {
        return dev.playerid == line.player_name;
      });
      return device[0].location_from;
    },
    SET_LOCATION_TO: function (line) {
      let device = this.DEVICES.filter((dev) => {
        return dev.playerid == line.player_name;
      });
      return device[0].location_to;
    },
  },
};
</script>
